
import { defineComponent } from "vue";
import RequisiteCatalogue from "@/components/catalogues/RequisiteCatalogue/RequisiteCatalogue.vue";

export default defineComponent({
  components: {
    RequisiteCatalogue,
  },
  props: {},
  methods: {},
  computed: {},
  data() {
    return {};
  },
});
